import React, { useState } from "react";
import { DynamicList } from "../components/Shared";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  FETCH_ORDER_ITEMS_ADMIN,
  UPDATE_ORDER_ITEM_STATUS,
} from "../graphql/modules";
import moment from "moment";
import { Button, Modal, Pagination, Popconfirm, Tag } from "antd";
import { Link } from "react-router-dom";
import { successNotify } from "../util/notification";
import TextArea from "antd/es/input/TextArea";

export const ReturnOrder = () => {
  const [limit] = useState(10);
  const [page, setPage] = useState(1);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [note, setNote] = useState("");

  // fetch order
  const { data, loading, refetch } = useQuery(FETCH_ORDER_ITEMS_ADMIN, {
    variables: {
      limit,
      offset: page - 1,
    },
    // fetchPolicy:"network-only"
  });
  const allOrderItemsList = data?.FetchOrderItemsAdmin?.result || [];
  const total = data?.FetchOrderItemsAdmin?.count || 0;

  const onChangePagination = (p) => {
    setPage(p);
  };

  const priceSelect = (data) => {
    if (
      (data?.bottomSizing && data?.topSizing) ||
      (!data?.bottomSizing && !data?.topSizing)
    ) {
      return data?.price;
    } else if (data?.topSizing) {
      return data?.topPrice;
    } else {
      return data?.bottomPrice;
    }
  };

  const calculatePrice = (data) => {
    const price = data?.haveDiscount
      ? parseFloat(
          priceSelect(data) - (priceSelect(data) * data?.discount) / 100
        )
      : parseFloat(priceSelect(data) || 0.0);
    return price;
  };

  const [AddNewAddressMutation, { loading: statusLoading }] = useMutation(
    UPDATE_ORDER_ITEM_STATUS
  );
  const onSubmitRefund = async ({ returnStatus }) => {
    try {
      const {
        data: { UpdateOrderItemStatusAdmin },
      } = await AddNewAddressMutation({
        variables: {
          orderItemId: isRejectModalVisible,
          returnStatus,
          note
        },
      });
      if (UpdateOrderItemStatusAdmin.success) {
        successNotify(UpdateOrderItemStatusAdmin.message);
        setIsRejectModalVisible(false);
        setNote("");
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const columns = [
    {
      title: "Order Id",
      dataIndex: "order",
      key: "orderId",
      render: (data) => data.orderId,
    },
    {
      title: "Order Item Name",
      dataIndex: "name",
      key: "orderItemName",
    },
    {
      title: "Return Date",
      render: (text, record) =>
        moment(Number(record.returnInitDate)).format("MM/DD/YYYY T hh:MM A"),
    },
    {
      title: "Price",
      render: (_, record) => (
        <p style={{ margin: 0 }}>
          {calculatePrice({
            ...record,
            haveDiscount:
              record?.haveDiscount &&
              ((record?.discountType !== "preOrder" &&
                !record?.isPreOrderItem) ||
                (record?.discountType !== "instock" && record?.isPreOrderItem)),
          })}{" "}
          USD
        </p>
      ),
    },
    {
      title: "Payment Method",
      render: (_, record) => (
        <p style={{ margin: 0 }}>
          {record?.refundPaymentMethod === "ORIGINAL_PAYMENTS"
            ? "ORIGINAL PAYMENTS"
            : "CHILLFIT STORE CREDIT"}
        </p>
      ),
    },
    {
      title: "Status",
      render: (_, record) => getStatus(record.returnStatus),
    },
    {
      title: "Actions",
      render: (_, record) => {
        return (
          <div>
            <Link to={`/orders/${record?.order?._id}`} className="mt-2 mr-3">
              <Button
                type="primary"

                // disabled={!qtc || size?.length > 0}
                // onClick={() => addSize("all")}
              >
                View
              </Button>
            </Link>
            {record?.returnStatus === "Returning" ? (
              <>
                <Popconfirm
                  title="Are you sure?"
                  okType="danger"
                  onConfirm={(e) => {
                    onSubmitRefund({
                      orderItemId: record._id,
                      returnStatus: "Refunded",
                    });
                    e.stopPropagation();
                  }}
                >
                  <Button className="mt-2 mr-3" type="primary" danger>
                    Accept
                  </Button>
                </Popconfirm>
                <Popconfirm
                  title="Are you sure?"
                  okType="danger"
                  onConfirm={(e) => {
                    setIsRejectModalVisible(record._id);
                    e.stopPropagation();
                  }}
                >
                  <Button type="default" danger>
                    Reject
                  </Button>
                </Popconfirm>
              </>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        title="Reject note"
        open={isRejectModalVisible}
        onOk={() => {
          onSubmitRefund({
            returnStatus: "Rejected",
          });
        }}
        onCancel={() => {
          setIsRejectModalVisible(false);
          setNote("");
        }}
      >
        <TextArea
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Enter rejection note..."
        />
      </Modal>
      <DynamicList
        title="All returned order items"
        data={allOrderItemsList}
        columns={columns}
        loading={loading}
        pagination={
          <Pagination
            className="pagination"
            total={total}
            pageSize={limit}
            current={page}
            showSizeChanger={false}
            hideOnSinglePage
            onChange={onChangePagination}
          />
        }
        expandable={{
          expandedRowRender: (record) => (
            <div>
              <div className="d-flex">
                <p className="mr-2">
                  <b>Shipping company:</b>
                </p>
                <p>{record?.shippingCompanyName}</p>
              </div>
              <div className="d-flex">
                <p className="mr-2">
                  <b>Tracking number:</b>
                </p>
                <p>{record?.trackingNumber}</p>
              </div>
              <div className="d-flex">
                <p className="mr-2">
                  <b>Shipping URL:</b>
                </p>
                <p>{record?.shippingUrl}</p>
              </div>
              <div className="mb-4">
                <p className="">
                  <b>Return reason:</b>
                </p>
                <ul>
                  {record?.customerReturnReasons?.map((item, index) => (
                    <p key={item}>
                      {index + 1}. {item}
                    </p>
                  ))}
                </ul>
              </div>
              <div className="">
                <p className="">
                  <b>Return note:</b>
                </p>
                <p
                  style={{
                    paddingLeft: "40px",
                  }}
                >
                  {record?.customerReturnNote}
                </p>
              </div>
            </div>
          ),
          rowExpandable: (record) => record.name !== "Not Expandable",
        }}
      ></DynamicList>
    </>
  );
};

const getStatus = (status) => {
  switch (status) {
    case "Returning":
      return <Tag color="orange">{status}</Tag>;
    case "Refunded":
      return <Tag color="green">{status}</Tag>;
    case "Rejected":
      return <Tag color="red">{status}</Tag>;

    default:
      break;
  }
};
