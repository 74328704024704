import React, { Fragment, useEffect, useState } from "react";
import { Button, Input, Spin } from "antd";
import { DynamicList } from "../components/Shared";
import {
  AboutContent,
  ContactContent,
  ProductView,
  ProductAdd,
} from "../components/ContentManagement";
import { PolicyFaq } from "../components/ContentManagement/PolicyCheckout";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  FETCH_ABOUT_CONTACT_CONTENT,
  SENT_NEWSLETTER,
  UPDATE_CONTENT,
} from "../graphql/modules";
import { successNotify, warnNotify } from "../util";
import OrderPageContent from "../components/ContentManagement/OrderPageContent";

export const ContentManagement = () => {
  const [aboutContent, setAboutContent] = useState("");
  const [contactContent, setContactContent] = useState("");
  const [subject, setSubject] = useState("");
  const [title, setTitle] = useState("");
  const [products, setProducts] = useState([]);
  const [orderNoteLabel, setOrderNoteLabel] = useState("");
  const [aboutImageOrVideo, setAboutImageOrVideo] = useState("");
  const [returnPageRightContent, setReturnPageRightContent] = useState("");
  const [returnPageImageOrVideo, setReturnPageImageOrVideo] = useState("");
  const [reasonList, setReasonList] = useState([]);
  const [storeCreditDescription, setStoreCreditDescription] = useState("");
  const [originalPaymentDescription, setOriginalPaymentDescription] =
    useState("");

  // fetch about and contact
  const {
    data,
    refetch,
    loading: contentLoading,
  } = useQuery(FETCH_ABOUT_CONTACT_CONTENT);

  useEffect(() => {
    const returnData = data?.FetchDynamicText || {};
    setAboutContent(returnData?.about);
    setAboutImageOrVideo(returnData?.aboutImageOrVideo);
    setContactContent(returnData?.contactUs);
    setOrderNoteLabel(returnData?.orderNoteLabel);
    setReturnPageRightContent(returnData?.returnPageRightContent);
    setReturnPageImageOrVideo(returnData?.returnPageImageOrVideo);
    setReasonList(returnData?.reasonList || []);
    setStoreCreditDescription(returnData?.storeCreditDescription);
    setOriginalPaymentDescription(returnData?.originalPaymentDescription);
  }, [data]);

  const onAboutContentChange = (value) => {
    setAboutContent(value);
  };

  const onContactContentChange = (value) => {
    setContactContent(value);
  };

  // call api set about text and contact text
  const [UpdateContent, { loading }] = useMutation(UPDATE_CONTENT);
  const saveContent = async () => {
    try {
      const {
        data: { UpdateDynamicText },
      } = await UpdateContent({
        variables: {
          about: aboutContent,
          aboutImageOrVideo,
          contactUs: contactContent,
          orderNoteLabel,
          returnPageRightContent,
          returnPageImageOrVideo,
          reasonList,
          storeCreditDescription,
          originalPaymentDescription,
        },
      });

      if (UpdateDynamicText.success) {
        successNotify(UpdateDynamicText.message);
        refetch();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // send newsletter api
  const [SendNewsLetter, { loading: newsletterLoading }] =
    useMutation(SENT_NEWSLETTER);
  const sendNewsletter = async () => {
    const productDetails = products.map((item) => ({
      productId: item.product._id,
      sizeType: item?.product?.isAccessories ? null : item?.product?.sizeType,
    }));
    try {
      const {
        data: { SentNewsLatter },
      } = await SendNewsLetter({
        variables: {
          productDetails,
          subject,
          title,
        },
      });
      if (SentNewsLatter.success) {
        successNotify(SentNewsLatter.message);
        setSubject("");
        setTitle("");
        setProducts([]);
      } else {
        warnNotify(SentNewsLatter.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Fragment>
      <Spin spinning={loading || contentLoading}>
        <DynamicList tableHide title="About Page">
          <AboutContent
            onTextHandler={onAboutContentChange}
            contentText={aboutContent}
            aboutImageOrVideo={aboutImageOrVideo}
            setAboutImageOrVideo={setAboutImageOrVideo}
            saveContent={saveContent}
            loading={loading}
          />
        </DynamicList>
        <DynamicList tableHide title="Order Return Page">
          <OrderPageContent
            reasonList={reasonList}
            setReasonList={setReasonList}
            onTextHandler={setReturnPageRightContent}
            contentText={returnPageRightContent}
            aboutImageOrVideo={returnPageImageOrVideo}
            setAboutImageOrVideo={setReturnPageImageOrVideo}
            saveContent={saveContent}
            loading={loading}
            storeCreditDescription={storeCreditDescription}
            setStoreCreditDescription={setStoreCreditDescription}
            originalPaymentDescription={originalPaymentDescription}
            setOriginalPaymentDescription={setOriginalPaymentDescription}
          />
        </DynamicList>
        <DynamicList tableHide title="Contact">
          <ContactContent
            onTextHandler={onContactContentChange}
            contentText={contactContent}
            saveContent={saveContent}
            loading={loading}
          />
        </DynamicList>
      </Spin>

      <DynamicList tableHide title="Newsletter">
        <div className="mb-4">
          <label className="mb-2">Email Subject</label>
          <Input
            size="large"
            placeholder="Email Subject"
            onChange={(e) => setSubject(e.target.value)}
            value={subject}
          />
        </div>
        <div className="mb-4">
          <label className="mb-2">Title</label>
          <Input
            size="large"
            placeholder="Newsletter Title"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
        </div>
        <div className="mb-4">
          <div className="mb-2">
            <label>Select Products</label>
          </div>
          <ProductView products={products} />
          <ProductAdd setProducts={setProducts} />
        </div>
        <div className="text-right">
          <Button
            type="primary"
            disabled={
              !subject || !title || products.length === 0 || newsletterLoading
            }
            loading={newsletterLoading}
            onClick={sendNewsletter}
          >
            Send Email
          </Button>
        </div>
      </DynamicList>
      <DynamicList title="Order Note Label" tableHide>
        <Input
          size="large"
          placeholder="Write label..."
          onChange={(e) => setOrderNoteLabel(e.target.value)}
          value={orderNoteLabel}
        />
        <div className="mt-4 text-right">
          <Button
            type="primary"
            onClick={saveContent}
            disabled={!orderNoteLabel || loading}
            loading={loading}
          >
            Save
          </Button>
        </div>
      </DynamicList>
      <DynamicList title="Policy For Checkout" tableHide>
        <PolicyFaq />
      </DynamicList>
    </Fragment>
  );
};
